import React from 'react'
import banner from "../../Assests/banner1.jpg"
import "./Footer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebookF, faYoutube, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { useState } from 'react';
const Footer = () => {
    const [isEmailHovered, setIsEmailHovered] = useState(false);
    const [isPhoneHovered, setIsPhoneHovered] = useState(false);

    const handleMouseEnter = (setHovered) => {
        setHovered(true);
    };

    const handleMouseLeave = (setHovered) => {
        setHovered(false);
    };

    const hoverStyle = {
        color: '#53b5a3', // Change the color on hover
        textDecoration: 'underline', // Add underline effect
    };
    return (
        <>

            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="footer-contact">
                                        <h2>Talk to us about your utility needs.</h2>
                                        <p>
                                            <FontAwesomeIcon style={{ width: '1.5em', height: "1.2em", color: "black" }} icon={faMapMarkerAlt} /> Unit MF6A Old Mill Complex Brown Street Dundee DD1 5EG
                                        </p>
                                        <p
                                            onMouseEnter={() => handleMouseEnter(setIsEmailHovered)}
                                            onMouseLeave={() => handleMouseLeave(setIsEmailHovered)}
                                        >
                                            <FontAwesomeIcon style={{ width: '1.5em', height: '1.2em', color: 'black' }} icon={faEnvelope} />
                                            &nbsp;
                                            {/* <span>Email :</span> */}
                                            <span style={isEmailHovered ? hoverStyle : {}}> admin@effectiveconsumerservices.co.uk</span>
                                        </p>
                                        <p
                                            onMouseEnter={() => handleMouseEnter(setIsPhoneHovered)}
                                            onMouseLeave={() => handleMouseLeave(setIsPhoneHovered)}
                                        >
                                            <FontAwesomeIcon style={{ width: '1.5em', height: '1.2em', color: 'black' }} icon={faPhoneAlt} />
                                            &nbsp;
                                            {/* <span></span> */}
                                            <span style={isPhoneHovered ? hoverStyle : {}}> 0330 001 0427</span>
                                        </p>

                                        <div className="footer-social">
                                            <a
                                                href="https://www.facebook.com/share/2kW2dcZMnCHe4iut/?mibextid=qi2Omg"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "50%",
                                                    width: "40px", // Adjust the size here
                                                    height: "40px",
                                                    backgroundColor: "#6ad0be", // Yellow background like in the image
                                                    margin: "0 10px", // Space between icons
                                                    color: "#fff", // Icon color
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faFacebookF} />
                                            </a>

                                            <a
                                                href="https://www.linkedin.com/company/effective-consumer-services/"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "50%",
                                                    width: "40px",
                                                    height: "40px",
                                                    backgroundColor: "#6ad0be",
                                                    margin: "0 10px",
                                                    color: "#fff",
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faLinkedinIn} />
                                            </a>


                                        </div>

                                    </div>
                                </div>



                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="footer-newsletter">
                                <h4>Effective Consumer Services ltd</h4>
                                {/* <p> */}
                                    {/* Powered by <a style={{color:"#55c2ae"}} href="https://marketingsatisfaction.com/#">Marketing Satisfaction</a> </p> */}

                            </div>
                        </div>

                    </div>
                </div>
                <div className="container copyright">
                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                &copy; <a href="#">Terms of use| Privacy Environmental Policy</a>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Copyright © 2024 Effective consumer services |Powered by <a href="https://marketingsatisfaction.com/#">Marketing Satisfaction</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default Footer