import React from 'react'
import "./Contact.css"
import { motion } from "framer-motion"
import about from "../../Assests/contact.jpg"
import { useState } from 'react'
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import c1 from "../../Assests/co.jpg"
import emailjs from '@emailjs/browser'

const Contactus = () => {
          const [showAlert, setShowAlert] = useState(false);

          const loginalert = () => {
            setShowAlert(true);
          };
        
          const closeAlert = () => {
            setShowAlert(false);
          };
          const [name, setName] = useState('');
          const [phone, setPhone] = useState('');
          const [company, setCompanyname] = useState('');
          const [email, setEmail] = useState('');
          const [message, setMessage] = useState('');
          const handleSubmit = (e) => {
                    e.preventDefault();
                    const serviceId = 'service_17vlmcw';
                    const templateId = 'template_0jmobmi';
                    const publickey = '3FK3DLlNpOIP_T7yG';


                    const templateParams = {
                              from_name: name,
                              from_email: email,
                              from_phone: phone,
                              from_company: company,
                              to_name: 'Khurram Khan',
                              message: message,
                    }
                    emailjs.send(serviceId, templateId, templateParams, publickey)
                              .then((response) => {
                                        console.log('Email sent sucsessfully');
                                        setName('');
                                        setEmail('');
                                        setPhone('');
                                        setMessage('');
                                        setCompanyname('');

                              })
                              .catch((error) => {
                                        console.error('error sending email:', error)
                              })
          }




          const containerStyle = {
                    width: "100%",
                    height: "400px",
          };

          const center = {
                    lat: 24.8607, // Karachi Latitude
                    lng: 67.0011, // Karachi Longitude
          };
          const fadeInRight = {
                    hidden: { opacity: 0, x: 100 },
                    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeInOut" } },
          };
          const fadeInUp = {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
          };
          const fadeInLeft = {
                    hidden: { opacity: 0, x: -100 },
                    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeInOut" } },
          };
          return (

                    <>
                              <div className="banner-container">
                                        <div className="banner-overlay"></div> {/* Overlay */}
                                        <div className="banner-content">
                                                  <h1 class="tittle " style={{ color: "#5dc1ad", fontWeight: "700" }}>Contact Us</h1>
                                                  <div className="gradient-line"></div>
                                                  <br />
                                                  <motion.p
                                                            initial="hidden"
                                                            whileInView="visible"
                                                            variants={fadeInLeft}
                                                            viewport={{ once: true }}
                                                            className="subtitle" >You are few steps away from finding out the right deal for your business. <br />
                                                            Fill out our contact form and one of our pricing specialist will give you a call to find the deal that's just right for you.</motion.p>
                                        </div>
                                        <img src={c1} alt="Banner" style={{ height: "35em" }} className="banner-image" />
                              </div>
                              {/*  */}
                              <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        variants={fadeInUp}
                                        viewport={{ once: true }}
                                        className="contact-container">

                                        <div className="form-section" onSubmit={handleSubmit}>

                                                  <form>
                                                            <div className="form-group">
                                                                      <label>Full Name:</label>
                                                                      <input onChange={(e) => setName(e.target.value)}
                                                                                value={name} type="text" placeholder="Name" className="input-field" />
                                                            </div>
                                                            <div className="form-group">
                                                                      <label>Email Address:</label>
                                                                      <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" className="input-field" />
                                                            </div>
                                                            <div className="form-group">
                                                                      <label>Phone Number:</label>
                                                                      <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder="Phone Number" className="input-field" />
                                                            </div>
                                                            <div className="form-group">
                                                                      <label>Company Name:</label>
                                                                      <input value={company} onChange={(e) => setCompanyname(e.target.value)} type="text" placeholder="Company Name" className="input-field" />
                                                            </div>
                                                            <div className="form-group">
                                                                      <label>Message:</label>
                                                                      <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message" className="input-field message-field" />
                                                            </div>
                                                            <div>
                                                            <button type="submit" className="submit-button" onClick={loginalert}>
                                                                      Send Message
                                                            </button>
                                                            {showAlert && (
                                                                      <div className="custom-alert-overlay">
                                                                                <div className="custom-alert-box">
                                                                                          <h2>Successfully</h2>
                                                                                          <p>Email sent</p>
                                                                                          <button className="alert-close-button" onClick={closeAlert}>
                                                                                                    Close
                                                                                          </button>
                                                                                </div>
                                                                      </div>
                                                            )}
                                                            </div>
                                                  </form>
                                        </div>
                                        <div className="map-section">
                                                  <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
                                                            <div style={{ width: "100%" }}><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Unit%20MF6A,%20Old%20Mill%20Complex,%20Brown%20Street,%20Dundee,%20DD1%205EG+(Effective%20consumer%20services)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                                                            <a href="https://www.gps.ie/">gps vehicle tracker</a></iframe></div>
                                                  </LoadScript>
                                        </div>
                              </motion.div>

                    </>
          )
}

export default Contactus