// TestimonialSlider.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation } from 'swiper/modules';
import './Testimonials.css';

const testimonials = [
  {
    name: 'Client 1',
    profession: 'Profession 1',
    image: 'https://via.placeholder.com/150', // Replace with actual image
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    name: 'Client 2',
    profession: 'Profession 2',
    image: 'https://via.placeholder.com/150', // Replace with actual image
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    name: 'Client 3',
    profession: 'Profession 3',
    image: 'https://via.placeholder.com/150', // Replace with actual image
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
];

const Testimonials = () => {
  return (
          <div className="testimonial-slider-container">
          <h2 className="testimonial-heading">OUR CLIENT'S SAY</h2>
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            modules={[Navigation]} // Add Navigation module
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="testimonial-card">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="testimonial-image"
                  />
                  <h3 className="client-name">{testimonial.name}</h3>
                  <p className="client-profession">{testimonial.profession}</p>
                  <p className="testimonial-text">{testimonial.text}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          
          {/* Custom Navigation Buttons */}
          <div className="swiper-button-prev">Previous</div>
          <div className="swiper-button-next">Next</div>
        </div>
  );
};

export default Testimonials;
