import React from 'react'
import { motion } from "framer-motion"
import "./Homee.css"
const Homee = () => {
   const fadeInLeft = {
      hidden: { opacity: 0, x: -100 },
      visible: { opacity: 1, x: 0, transition: { duration: 0.8, ease: "easeInOut" } }
   };

   const fadeInUp = {
      hidden: { opacity: 0, y: 50 },
      visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeInOut" } }
   };
   return ( 
      <>

{/* 

<div id="banner">
                        <div id="banner-images">
                            <img src="https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547__480.jpg" alt=""/>
                            <img src="https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832__480.jpg" alt=""/>
                            <img src="https://images.pexels.com/photos/3586966/pexels-photo-3586966.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt=""/>
                            <img src="https://c4.wallpaperflare.com/wallpaper/362/276/920/nature-4k-pc-full-hd-wallpaper-thumb.jpg" alt=""/>
                            <img src="https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547__480.jpg" alt=""/>
                        </div>
                        <h1>Banner Caption goes here!</h1>
                    </div>


 */}













      {/*  */}
         <div className="banner" style={{ height: "48em" }}>

            <motion.div
               initial="hidden"
               whileInView="visible"
               variants={fadeInLeft}
               viewport={{ once: true }}
               className="container"
            >
               <motion.h2
                  variants={fadeInLeft}
                  style={{
                     color: "#ffffff",
                     fontWeight: "800",
                     fontSize: "clamp(2rem, 5vw, 2.5rem)", 
                     lineHeight: "clamp(55px, 6vw, 60px)", 
                     // fontFamily: "Inte",
                     fontfamily: "Lora, serif",
                     marginTop:"-1em"
                  }}
               >

                  <span style={{fontSize:"2.2rem",fontWeight:"900", fontfamily: "Lora, serif"}}>Why</span> pay more when you can save on your <span style={{fontSize:"2.2rem",fontWeight:"900"}}>commercial utility</span>
               </motion.h2>
               <br />
               
               <motion.h3
                  variants={fadeInUp}
                  className='text-gap'
                  style={{
                     color: "#f2e719",
                     fontWeight: "700",
                     fontSize: "clamp(2.1rem, 5vw, 2rem)", // Responsive font size
                     lineHeight: "clamp(55px, 6vw, 45px)", // Responsive line height
                     fontFamily: "Roboto",
                     marginTop:"1em"

                  }}
               >
                  <span style={{fontSize:"2.5rem",fontWeight:"900"}}>Working</span> for you to find the <span style={{fontSize:"2.5rem",fontWeight:"900"}}>right deal</span>
               </motion.h3>
            </motion.div>
         </div>
      </>
   )
}

export default Homee