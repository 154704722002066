import React from 'react'
import Homee from "../../Components/Homee/Homee"
import Slider from "../../Components/Slider/Sider"
const Homepage = () => {
  return (
    <>
    <Homee/>
   <Slider/>
     
    </>
  )
}

export default Homepage