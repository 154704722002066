// LogoSlider.js
// import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css"; // Custom styling
import img1 from "../../Assests/i1.png"
import img2 from "../../Assests/2.png"
import img3 from "../../Assests/3.png"
import img4 from "../../Assests/i3.png"
import img5 from "../../Assests/i4.png"
import img6 from "../../Assests/i5.png"
import img7 from "../../Assests/i6.png"
import img8 from "../../Assests/i7.png"
import img9 from "../../Assests/i8.png"
import { motion } from "framer-motion"
import bill from "../../Assests/bill.png"
import bill1 from "../../Assests/bill1.png"
import { useState } from "react";
import emailjs from '@emailjs/browser'
import o3 from "../../Assests/o5.png"
import o1 from "../../Assests/o7.png"
import o2 from "../../Assests/0.png"

import bill2 from "../../Assests/h1.png"

const Sider = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompanyname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    const serviceId = 'service_dk8r45w';
    const templateId = 'template_psb2typ';
    const publickey = '95jsllZ-Ey3LlKDKq';


    const templateParams = {
      from_name: name,
      from_email: email,
      from_phone: phone,
      from_company: company,
      to_name: 'Khurram Khan',
      message: message,
    }
    emailjs.send(serviceId, templateId, templateParams, publickey)
      .then((response) => {
        console.log('Email sent sucsessfully', response);
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setCompanyname('');

      })
      .catch((error) => {
        console.error('error sending')
      })
  }
  const [showAlert, setShowAlert] = useState(false);

  const loginalert = () => {
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };




  const settings = {
    dots: false, // Disable dots
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, // If you also want to remove arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const logos = [
    { name: "British Gas", src: img1 },
    { name: "British Gas Lite", src: img2 },
    { name: "Scottish Power", src: img3 },
    { name: "SSE Energy Solutions", src: img4 },
    { name: "Opus Energy", src: img5 },
    { name: "Opus Energy", src: img6 },
    { name: "Opus Energy", src: img7 },
    { name: "Opus Energy", src: img8 },
    { name: "Opus Energy", src: img9 },

  ];
  const fadeInRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeInOut" } },
  };
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };
  const fadeInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeInOut" } },
  };

  return (
    <>


      {/* about */}

      <div className="blog_section layout_padding" style={{ background: "white" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <motion.div
                initial="hidden"
                whileInView="visible"
                variants={fadeInLeft}
                viewport={{ once: true }}
                className="blog_img"
              >
                <img
                  src={bill2}
                  alt="ECS"
                  style={{
                    width: "100%",          // Responsive width
                    maxWidth: "400px",       // Maximum width to make the image smaller
                    height: "auto",          // Maintain aspect ratio
                  }}
                />
              </motion.div>
            </div>
            <div className="col-md-6">
              <h1
                className="blog_taital"
                style={{
                  color: "#48bea8",
                  fontFamily: "Abril Fatface, serif",
                  fontSize: "2.1rem"
                }}
              >
                <span
                  style={{
                    fontSize: "2.4rem",
                    fontFamily: "Abril Fatface, serif",
                    fontWeight: "700"
                  }}
                >
                  WHY
                </span>&nbsp;
                ECS?
              </h1>

              <p
                className="blog_text"
                style={{
                  color: "black",
                  fontFamily: "Abril Fatface, serif",
                  fontSize: "20px"
                }}
              >
                Our aim is to give the best possible service and prices so you can save and invest where it is needed the most. Unlike many other brokers, we value lasting business relationships. We listen to you and come up with the best quote according to your needs. There is a reason why many of our clients come back to us for their utilities.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* second section */}
      <div class="blog_sections layout_padding" style={{ background: "white" }}>

        <div class="container">
          <div class="row">

            <div class="col-md-6">
              <h1 class="blog_taitals" style={{ color: "#ffeb0a", fontfamily: "Abril Fatface, serif", fontSize: "2rem" }}>How Does It <span style={{ fontSize: "2.4rem", fontWeight: "700", fontFamily: "Abril Fatface, serif" }}>Work?</span></h1>

              <p class="blog_texts" style={{ color: "black", fontfamily: "Federo , sans-serif", fontSize: "19px" }}>Our experienced consultants offer trusted and tailored advice for commercial utilities. We can help you save money on your business energy and water rates by providing customized solutions for your company's needs.</p>
            </div>
            <div class="col-md-6">
              <br />
              <motion.div
                initial="hidden"
                whileInView="visible"
                variants={fadeInLeft}
                viewport={{ once: true }}
                class="blog_imgs"

              ><img src={bill} /></motion.div>
            </div>
          </div>
        </div>

      </div>
      {/* 3rd */}
      <div class="blog_section layout_padding" style={{ background: "white" }}>

        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <motion.div
                initial="hidden"
                whileInView="visible"
                variants={fadeInLeft}
                viewport={{ once: true }}
                class="blog_img"><img src={bill1} /></motion.div>
            </div>
            <div class="col-md-6">
              <h1 class="blog_taital" style={{ color: "#51c1ac", fontfamily: "Abril Fatface, serif", fontSize: "2rem" }}>We can <span style={{ fontSize: "2.4rem", fontWeight: "700", fontFamily: "Abril Fatface, serif" }}>help </span>you save .</h1>

              <p class="blog_text" style={{ color: "black", fontfamily: "Trirong, serif", fontSize: "19px" }}>Ready to save? Our professional sales team is there for you. We just need a few simple details from you so we can instantly give you the best possible quotes from the leading utility suppliers.

                Why not call us on 0330 001 0427, if you prefer WhatsApp, then send us a message.
                Or
                Email us with a copy of your current bill and one of our dedicated representatives will guide you through.</p>
            </div>
          </div>
        </div>

      </div>

      {/*  */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInLeft}
        viewport={{ once: true }}
        class="container py-5">
        {/* <h1 class="text-body-emphasis" style={{ textAlign: "center", color: "#6bd4c1", fontFamily: "sans-serif", fontWeight: "800" }}>Who Do We Work With ?</h1> */}
        <h1 class="text-body-emphasis" style={{ textAlign: "center", color: "#5ec5b2", fontFamily: "Trirong,serif", fontWeight: "800", fontSize: "2.1rem" }} >
          We work with the UK's leading energy suppliers to offer you the best rates.
        </h1>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInLeft}
        viewport={{ once: true }}
        className="slider-container"
        style={{
          textAlign: "center"
        }}>
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <div key={index} className="logo-slide">
              <img src={logo.src} alt={logo.name} className="logo-image" />
            </div>
          ))}
        </Slider>
      </motion.div>
      {/* end */}
      {/* services */}
      <div class="services">
        <div class="container">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="titlepage">
                <h2>Our Services</h2>
                <p>Finding you the right service, ECS works efficiently so you don't have to!</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div id="serv_hover" class="services_box">
                <i><img style={{ width: "7em", height: "7em" }} src={o2} alt="#" /></i>
                <h3>Electricity</h3>
                <br />
                <p>We negotiate with suppliers on your behalf to get the best electricity product for you.</p>
                {/* <a class="right_irro" href="#"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></a> */}
              </div>
            </div>
            <div class="col-md-4">
              <div id="serv_hover" class="services_box">
                <i><img style={{ width: "6em", height: "6em" }} src={o1} alt="#" /></i>
                <h3>Water</h3>
                <br/> 
                <p>We take the confusion out of the equation and help you find the best water contract on your behalf</p>
                {/* <a class="right_irro" href="#"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></a> */}
              </div>
            </div>
            <div class="col-md-4">
              <div id="serv_hover" class="services_box">
                <i><img style={{ width: "7em", height: "7em" }} src={o3} alt="#" /></i>
                <h3>Gas</h3>
                <br />
                <p>.We find the best product and gain competitive tarrif for your gas supply.</p>
                {/* <a class="right_irro" href="#"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}

      <section className="section" style={{ marginTop: "10px" }} id="reservation">
        <div className="overlay"></div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={fadeInUp}
          viewport={{ once: true }}
          className="container"
        >
          <div onSubmit={handleSubmit} className="row">
            <div className="col-lg-6">
              <div className="contact-form">
                <form id="contact" action="" method="post">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 style={{ fontWeight: "700" }}>Get a Quick Quote</h4>
                      <div className="gradient-line"></div>
                      <br />
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <fieldset>
                        <input name="name" type="text" id="name" placeholder="Your Name*" required=""
                          onChange={(e) => setName(e.target.value)}
                          value={name} />
                      </fieldset>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <fieldset>
                        <input value={company} onChange={(e) => setCompanyname(e.target.value)} name="phone" type="text" id="phone" placeholder="Company Name*" required="" />
                      </fieldset>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <fieldset>
                        <input value={phone} onChange={(e) => setPhone(e.target.value)} name="phone" type="text" id="phone" placeholder="Phone number*" required="" />
                      </fieldset>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <fieldset>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} name="phone" type="text" id="phone" placeholder="Email*" required="" />
                      </fieldset>
                    </div>



                    <div className="col-lg-12">
                      <fieldset>
                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="message" rows="6" id="message" placeholder="Message" required=""></textarea>
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <div>
                          <button
                            type="submit"
                            id="form-submit"
                            onClick={loginalert}
                            className="main-button-icon"
                          >
                            Get your quote
                          </button>

                          {showAlert && (
                            <div className="custom-alert-overlay">
                              <div className="custom-alert-box">
                                <h2>Successfully</h2>
                                <p>message will be sent</p>
                                <button className="alert-close-button" onClick={closeAlert}>
                                  Close
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      </section>

      {/* blog */}




    </>
  );
};

export default Sider;
