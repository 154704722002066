import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import logo from "../../Assests/logo.png";
import { motion } from "framer-motion";

export default function Navbarr() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
   

    return () => {
      document.body.style.overflow = "unset"; // Cleanup on unmount
    };
  }, [isNavOpen]);

  const fadeInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 2, ease: "easeInOut" } },
  };

  return (
    <>
      <Container state={isNavOpen ? 1 : 0}>
        <div className="toggle">
          {isNavOpen ? (
            <MdClose style={{ width: "4em", height: "2em" }} onClick={() => setIsNavOpen(false)} />
          ) : (
            <GiHamburgerMenu
              onClick={(e) => {
                e.stopPropagation();
                setIsNavOpen(true);
              }}
            />
          )}
        </div>
        <div
          className="brand"
        >
          <h3 style={{ color: "white" }}>
            <img src={logo} alt="Logo" />
          </h3>
        </div>
        <div
          className={`links ${isNavOpen ? "show" : ""}`}
        >
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/Aboutus">About us</a></li>
            <li><a href="/contactus">Contact us</a></li>
          </ul>
        </div>
      </Container>
      {/* <div className="gradient-line"></div> */}
    </>
  );
}
const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12vh;
  background-color: #ffffff;
  /* box-shadow:0 5px 5px black; */
  padding: 0 2rem;
  font-family: "Inter", sans-serif;

  .toggle {
    display: none;
    color: #000000;
  }

  .gradient-line {
    height: 5px;
    background: linear-gradient(
      to right,
      rgb(14, 255, 239),
      rgb(206, 203, 53),
      rgb(0, 0, 0),
      rgb(185, 185, 185)
    );
    width: 100%;
  }

  .brand {
    display: flex;
    align-items: center;
    img {
      cursor: pointer;
      width: auto;
      height: 7em;
      width: 7em;
      object-fit: contain;
    }
  }

  .links {
    ul {
      display: flex;
      gap: 2rem;
      list-style-type: none;
      font-weight: 700;
      font-size: 20px;
      color: #000000;

      li a {
        text-decoration: none;
        color: #000000;
        font-weight: 700;
        font-size: 20px;
        cursor: pointer;
        transition: color 0.3s ease, background 0.3s ease;

        &:hover {
          color: yellow;
          background:black; 
          padding: 5px 10px;
          border-radius: 5px;
        }
      }
    }
  }

  @media screen and (min-width: 280px) and (max-width: 1080px) {
    position: relative;
    padding: 1rem 0.5rem;
    z-index: 10;

    .toggle {
      padding-right: 1rem;
      display: block;
      position: absolute;
      right: 1rem; /* Align toggle to the right */
      z-index: 2;
    }

    .brand {
      display: flex;
      justify-content: flex-start; /* Align logo to the left */
      align-items: center;
      width: 100%;
      img {
        height: 5.3em;
        width: 5.3em;
      }
    }

    .links {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: #ffffff;
      transform: ${(props) =>
        props.state ? "translateY(0)" : "translateY(-100%)"};
      opacity: ${(props) => (props.state ? "1" : "0")};
      visibility: ${(props) => (props.state ? "visible" : "hidden")};
      transition: transform 0.2s ease, opacity 0.2s ease, visibility 0.5s ease;

      ul {
        flex-direction: column;
        text-align: center;
        height: 100%;
        justify-content: center;

        li a {
          color: #000000;
          font-size: 21px;
          margin-bottom: 1rem;
        }
      }
    }
  }
`;